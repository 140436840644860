import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as LogoIconLightBg } from '../../media/images/LogoIconLightBg.svg';
import { ReactComponent as RaiseRightLogo } from '../../media/images/RaiseRightLogo.svg';

import { SearchBar } from './SearchBar';
import NavButtons from '../nav/NavButtons';

import { useRootSelector } from 'rootStore';
import { createStyles } from 'utils/createStyle';
import { applyTerm, updateSearch } from 'common/features/store/duck/search/duck';
import { getPath, Routes } from 'routes';
import {
    selectHomeDetailsAsyncState,
    selectHomeDetails,
} from 'common/features/store/duck/home/duck';
import { OrgSelectDropdown } from './components/OrgSelectDropdown';
import { Brand } from 'common/features/store/duck/search-generic/utils/searchResultBrandsToBrands';
import Cookies from 'universal-cookie';
import { selectUserRole } from 'common/features/store/duck/account/duck';
import { ShopNavOptions } from './components/ShopNavOptions';
import { selectAffiliateEnabledWeb, selectIsP2PEnabled } from 'common/features/store/duck/ffs';
import { UnAuthMobileMenu } from './components/UnAuthMobileMenu';
import { ChevronDown } from 'react-feather';
import { colors } from 'styles/settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { P2pSources } from 'features/account-settings/components/SettingsTab/InviteFriendsFamily';

const styles = createStyles({
    container: [
        'shadow-lg pb-2',
        {
            '@media (min-width: 768px)': {
                minHeight: '100px',
            },
        },
    ],
    headerContainer: [
        'grid px-4 md:px-8 pt-4 pb-3 gap-x-0 md:gap-x-4 justify-items-end md:justify-items-stretch items-center',
        {
            gridTemplateColumns: 'max-content auto max-content',
        },
    ],
    headerLink: [
        'flex items-center gap-1 text-brand text-base font-normal px-3 md:text-16px md:font-semibold md:px-4 no-underline xl:truncate',
    ],
    logoContainer: ['w-auto flex'],
    unAuthBigLogo: ['w-auto block pr-5'],
    authSmallLogo: ['w-full block lg:hidden'],
    authBigLogo: ['w-full hidden lg:block', { minWidth: '244px' }],
    logoSize: ['block', { width: '203px', height: '36px' }],
    unauthHeaderContainer: [
        'flex px-4 md:px-5 pt-4 pb-3 justify-center',
        {
            gridTemplateColumns: 'max-content auto max-content',
            alignItems: 'center',
        },
        { '@media (min-width: 600px)': { justifyContent: 'space-between' } },
    ],
    unAuthItemsContainer: [
        'items-center hidden',
        { '@media (min-width: 1140px)': { display: 'flex' } },
    ],
    signUpContainer: ['hidden', { '@media (min-width: 600px)': { display: 'flex' } }],
    mobileSignUpContainer: ['flex', { '@media (min-width: 600px)': { display: 'none' } }],
    mobileSignUpLinkContainer: [
        'flex items-center w-full lg:w-auto pr-4 xl:pr-4',
        { '@media (min-width: 600px)': { width: 'auto' } },
    ],
    signUpLink: [
        'bg-brand rounded-full text-white font-semibold flex items-center text-center no-underline text-base px-6 py-2 mx-auto w-full justify-center',
        { minWidth: 'max-content' },
        { '@media (min-width: 600px)': { width: 'auto', justifyContent: 'center' } },
    ],
    navOptionsContainer: [
        'flex justify-end lg:px-8 pt-2',
        { '@media (min-width: 1024px)': { borderTop: '1px solid lightGrey' } },
    ],
    navBar: 'flex lg:flex-row w-full relative',
    navBarWithOptions: 'flex items-center justify-between md:flex-row flex-wrap',
    navBarWithoutOptions: 'justify-end',
    orgSelectWithNavOptions: 'pr-4 md:pr-8 lg:pr-0 lg:order-3 order-2',
    dropdownOption: [
        'block mb-4 px-5 no-underline capitalize text-brand font-semibold text-base hover:underline focus:underline',
        { textWrap: 'nowrap' },
    ],
});

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];
const routes = [Routes.Shop, Routes.ShopOnline, Routes.ShopGiftCards] as const;
type NavOptionsRoute = ArrayElement<typeof routes>;

export const unAuthMenuItems = [
    {
        id: 'menu-how-it-works',
        label: 'How It Works',
        url: Routes.HowItWorks,
        menuItems: [
            {
                id: 'how-it-works',
                label: 'How It Works',
                url: Routes.HowItWorks,
            },
            {
                id: 'gift-cards',
                label: 'Gift Cards',
                url: Routes.HowItWorks,
            },
            {
                id: 'shop-online',
                label: 'Shop Online',
                url: Routes.HowItWorksShopOnline,
            },
            {
                id: 'browser-extension',
                label: 'Browser Extension',
                url: Routes.HowItWorksBrowserExtension,
            },
        ],
    },
    {
        id: 'brands',
        label: 'Brands',
        url: Routes.Brands,
    },
    {
        id: 'menu-who-we-help',
        label: 'Who We Help',
        url: Routes.WhoWeHelp,
        menuItems: [
            {
                id: 'who-we-help',
                label: 'Who We Help',
                url: Routes.WhoWeHelp,
            },
            {
                id: 'schools',
                label: 'Schools',
                url: Routes.WhoWeHelpSchools,
            },
            {
                id: 'churches',
                label: 'Churches',
                url: Routes.WhoWeHelpChurchers,
            },
            {
                id: 'sports-teams',
                label: 'Sports Teams',
                url: Routes.WhoWeHelpSportsTeams,
            },
            {
                id: 'bands',
                label: 'Bands & Music Programs',
                url: Routes.WhoWeHelpBands,
            },
            {
                id: 'booster-clubs',
                label: 'Booster Clubs',
                url: Routes.WhoWeHelpBoosterClubs,
            },
            {
                id: 'nonprofits',
                label: 'Nonprofits',
                url: Routes.WhoWeHelpNonProfit,
            },
        ],
    },
    {
        id: 'about',
        label: 'About',
        url: Routes.AboutUs,
    },
    {
        id: 'menu-learn',
        label: 'Learn',
        url: '',
        menuItems: [
            {
                id: 'coordinator-resources',
                label: 'Coordinator Resources',
                url: Routes.SupportOrganization,
            },
            {
                id: 'participant-resources',
                label: 'Participant Resources',
                url: Routes.SupportParticipant,
            },
            {
                id: 'fundraising-ideas',
                label: 'Fundraising Ideas',
                url: Routes.FundraisingIdeas,
            },
            {
                id: 'blog',
                label: 'Blog',
                url: Routes.Blog,
            },
            {
                id: 'impact-stories',
                label: 'Impact Stories',
                url: Routes.ImpactStories,
            },
        ],
    },
];

export const SiteHeader = () => {
    const { userId } = useRootSelector((s) => s.store.account);
    const dispatch = useDispatch<any>();
    const history = useHistory();
    const isAffiliateEnabled = useRootSelector(selectAffiliateEnabledWeb);
    const homeDetails = useRootSelector(selectHomeDetails);
    const homeDetailsAsyncState = useRootSelector((s) => selectHomeDetailsAsyncState(s));
    const accountRole = useSelector(selectUserRole);
    const { pathname } = useLocation();
    const [selectedTab, setSelectedTab] = useState<NavOptionsRoute>();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const isP2pEnabled =
        useRootSelector(selectIsP2PEnabled) &&
        Boolean(homeDetails?.defaultOrganization?.isP2PInviteEnabled);

    useEffect(() => {
        const matchedPath = matchPath(pathname, [...routes]);
        if (isAffiliateEnabled && matchedPath && matchedPath.isExact) {
            setSelectedTab(matchedPath.path as NavOptionsRoute);
        } else {
            setSelectedTab(undefined);
        }
    }, [isAffiliateEnabled, pathname]);

    /**
     * Temporary addition of a cookie to allow the Marketing Hubspot site header
     * to access a few pieces of user data for display purposes only.
     */
    const cookies = new Cookies();
    cookies.set(
        'rr_user_info',
        {
            first_name: homeDetails?.profile.firstName,
            organization_name: homeDetails?.defaultOrganization?.name,
            role: accountRole,
        },
        { path: '/' }
    );

    const applySearchTerm = (term: string) => {
        dispatch(applyTerm(term));

        if (history.location.pathname !== Routes.Brands) {
            history.push(Routes.Brands);
        }
    };

    const navigateToProductDetailPage = (item: Brand) => {
        if (item.id) {
            dispatch(updateSearch({ term: item.name }));
            if (item.isLocalProduct) {
                const [, , id] = item.id.split('-');
                history.push(getPath(Routes.ShopLocalProductDetails, { productId: id }));
            } else {
                const idWithSlug = item.urlSlug
                    ? `${item.id}-${item.urlSlug.replace(/\//g, '')}`
                    : item.id;
                history.push(getPath(Routes.BrandsDetails, { id: idWithSlug }));
            }
        }
    };

    const headerLayoutStyle = userId ? styles.headerContainer : styles.unauthHeaderContainer;
    const redirectRoute = history.location.pathname.startsWith(
        Routes.ShopAccountSettingsCoordinatorTab
    )
        ? Routes.CoordinatorDashboardRoot
        : Routes.Brands;

    if (!userId) {
        return (
            <div className={styles.container} id="main-header">
                <div className={headerLayoutStyle}>
                    <div className={styles.logoContainer}>
                        <div className={styles.unAuthBigLogo}>
                            <a href={'/'} className={styles.logoSize}>
                                <RaiseRightLogo className="h-full" />
                            </a>
                        </div>
                        <div className={styles.unAuthItemsContainer}>
                            {unAuthMenuItems.map((item) => (
                                <div className="relative group cursor-pointer" key={item.id}>
                                    {item.url === '' ? (
                                        <span className={styles.headerLink}>
                                            {item.label}
                                            {item.menuItems && item.menuItems.length > 0 && (
                                                <ChevronDown
                                                    className="chevron-icon"
                                                    color={colors.bonusPink}
                                                    size={20}
                                                />
                                            )}
                                        </span>
                                    ) : (
                                        <a className={styles.headerLink} href={item.url}>
                                            {item.label}
                                            {item.menuItems && item.menuItems.length > 0 && (
                                                <ChevronDown
                                                    className="chevron-icon"
                                                    color={colors.bonusPink}
                                                    size={20}
                                                />
                                            )}
                                        </a>
                                    )}

                                    <div className="z-50 absolute group-hover:visible hover:visible invisible bg-white shadow-lg pt-5 pb-1">
                                        {item.menuItems &&
                                            item.menuItems.length > 0 &&
                                            item.menuItems.map((subItem) =>
                                                item.label === subItem.label ? null : (
                                                    <div
                                                        key={subItem.id}
                                                        className="py-[0.6875rem]"
                                                    >
                                                        <a
                                                            className={styles.dropdownOption}
                                                            href={subItem.url}
                                                        >
                                                            <div className="flex items-center">
                                                                <div className="mr-2.5">
                                                                    {subItem.label}
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.signUpContainer + ' lg:w-auto'}>
                        <div className="flex items-center w-full lg:w-auto pr-4 xl:pr-4">
                            <Link className={styles.signUpLink} to={Routes.EnrollWithoutEnrollCode}>
                                Sign Up
                            </Link>
                        </div>
                        <div className="header_nav_group">
                            <NavButtons setMobileMenuOpen={setMobileMenuOpen} />
                        </div>
                    </div>
                </div>
                <div
                    className={
                        styles.mobileSignUpContainer + ' items-center mt-2 mx-5  justify-end '
                    }
                >
                    <div className={styles.mobileSignUpLinkContainer}>
                        <Link className={styles.signUpLink} to={Routes.EnrollWithoutEnrollCode}>
                            Sign Up
                        </Link>
                    </div>
                    <div className="header_nav_group">
                        <NavButtons setMobileMenuOpen={setMobileMenuOpen} />
                    </div>
                </div>
                <div className="hidden">
                    <div className="px-4">
                        <SearchBar
                            onSearch={applySearchTerm}
                            className="none md:hidden"
                            onItemSelected={navigateToProductDetailPage}
                        />
                    </div>
                </div>
                <UnAuthMobileMenu
                    mobileMenuOpen={mobileMenuOpen}
                    setMobileMenuOpen={setMobileMenuOpen}
                />
            </div>
        );
    }
    return (
        <div className={styles.container} id="main-header">
            <div className={headerLayoutStyle}>
                <div className={styles.logoContainer}>
                    <div className={styles.authSmallLogo}>
                        <Link to={Routes.Shop}>
                            <LogoIconLightBg />
                        </Link>
                    </div>
                    <div className={styles.authBigLogo}>
                        <Link to={Routes.Shop} className={styles.logoSize}>
                            <RaiseRightLogo className="h-full" />
                        </Link>
                    </div>
                </div>
                <div className="hidden md:block">
                    <SearchBar
                        className="header_search_bar"
                        onSearch={applySearchTerm}
                        onItemSelected={navigateToProductDetailPage}
                    />
                </div>
                <div className="header_nav_group">
                    <NavButtons />
                </div>
            </div>
            <div className="block md:hidden mt-2">
                <div className="px-4">
                    <SearchBar
                        onSearch={applySearchTerm}
                        className="none md:hidden header_search_bar"
                        onItemSelected={navigateToProductDetailPage}
                    />
                </div>
            </div>
            <div className={isAffiliateEnabled ? styles.navOptionsContainer : ''}>
                {homeDetailsAsyncState.loading ? (
                    <div className="flex items-center p-2 pr-0">
                        <div className="w-14">
                            <Skeleton />
                        </div>
                        <span>&nbsp;-&nbsp;</span>
                        <div className="w-28">
                            <Skeleton />
                        </div>
                    </div>
                ) : (
                    <>
                        <div
                            className={`${styles.navBar} ${
                                isAffiliateEnabled
                                    ? styles.navBarWithOptions
                                    : styles.navBarWithoutOptions
                            }`}
                        >
                            <div className={`${isP2pEnabled ? 'block sm:hidden' : 'block'}`}></div>
                            {isP2pEnabled ? (
                                <Link
                                    to={`${Routes.ShopAccountSettingsInviteFriendsFamilyOptionsTab}?source=${P2pSources.Header}`}
                                    className="hidden sm:inline order-1 order-1 pl-4 md:pl-8 lg:pl-0 no-underline text-brand text-xs"
                                    href="#"
                                >
                                    <FontAwesomeIcon icon={faUserPlus} /> Invite Friends & Family
                                </Link>
                            ) : null}
                            {isAffiliateEnabled ? (
                                <ShopNavOptions selectedTab={selectedTab} />
                            ) : (
                                <div className="flex-grow lg:flex-none lg:w-auto lg:order-2 order-3 w-full text-center" />
                            )}
                            <div
                                className={`${
                                    isAffiliateEnabled ? styles.orgSelectWithNavOptions : ''
                                }`}
                            >
                                <OrgSelectDropdown
                                    homeDetails={homeDetails}
                                    redirectRoute={redirectRoute}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
